import React from "react"
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import Seo from "../../components/SEO"
import Header from "../../components/HeaderC"
import PageNav from "../../components/PageNav"


const Supporters = (props) => {

    const intl = useIntl()

    return (
        <div>
            <Seo
                title={intl.formatMessage({ id: "supporters.title.seo" })}
            />
            <Header />

            <div>

                <div className="sir-breadcrumbs">
                    <Link class="text-sir-black-50" to="/"><FormattedMessage id="global.sir" /></Link>
                    <span class="text-sir-black-25 mx-1">/</span>
                    <Link class="text-sir-black-50" to="/great-love"><FormattedMessage id="global.menu.greatlove" /></Link>
                    <span class="text-sir-black-25 mx-1">/</span>
                    <FormattedMessage id="global.menu.supporters" />
                </div>

                <div className="sir-section sir-layout-2-3 text-center">
                    <h1 className="title-l uppercase mb-8">
                        <FormattedMessage id="supporters.title" />
                    </h1>
                    <ul>
                        <li>Tanel Kõivupuu</li>
                        <li>Nikolai Nikitin</li>
                        <li>Merike Rand</li>
                        <li>Sven Pertens</li>
                        <li>Tahel Metall OÜ</li>
                        <li>Metal-Disain OÜ</li>
                        <li>Haus Galerii</li>
                        <li>Dinamo GmbH</li>
                        <li>Eesti Kultuurkapital</li>
                        <li>Eesti Vabaõhumuuseum</li>
                        <li>Eesti Kunstimuuseum</li>
                    </ul>

                    <h1 className="title mt-16 mb-8">
                        <FormattedMessage id="supporters.subtitle2" />
                    </h1>
                    <ul>
                        <li>Eero Epner</li>
                        <li>E. Kivi Inseneribüroo</li>
                        <li>Egon Kivi</li>
                        <li>Janno Veskimets</li>
                        <li>Villu Roogna</li>
                        <li>Henry Tiisma</li>
                        <li>Lauri Roogna</li>
                        <li>Jarmo Veskimets</li>
                        <li>Mart Saar</li>
                        <li>Eve Harju</li>
                        <li>Märt Vaidla</li>
                        <li>Jaanus Vallsalu</li>
                        <li>Urmas Sepp </li>
                        <li>Roman Kuznetsov</li>
                        <li>Christen Puust</li>
                        <li>Aldermanni Metall OÜ</li>
                        <li>Helena Haller</li>
                        <li>Dagmar Ingi</li>
                        <li>Jaak Kõiva </li>
                        <li>Anneli Banner </li>
                        <li>Maria Hansar</li>
                        <li>Alissa Ivanova</li>
                        <li>Liina Keevallik </li>
                        <li>Rainar Aasrand</li>
                        <li>Freddy Saunanen</li>
                        <li>Triin Ruumet</li>
                        <li>Karolina Veetamm</li>

                    </ul>
                </div>
            </div>
            <PageNav />
        </div>
    )
}

export default Supporters;